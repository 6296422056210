
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

















































.help-page,
[class*='help-page--'] {
}

.help-page__hero {
  ::v-deep {
    @include mq($until: l) {
      background-color: white;
    }
    .section-hero__speedlines {
      @include mq($until: l) {
        display: none;
      }
    }
  }
}

.help-page__cta {
  ::v-deep {
    .cta__excerpt {
      margin-top: $spacing;
      font-size: 1.6rem;
    }

    .cta__btn {
      margin-top: $spacing;
    }

    @include mq(l) {
      .cta__excerpt {
        width: col(8);
        max-width: none;
        margin-top: $spacing * 2;
        font-size: 1.8rem;
      }

      .cta__btn {
        margin-top: $spacing * 2;
      }
    }
  }
}
