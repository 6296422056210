
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        














































.call-us {
  display: block;
  margin: $spacing * 2 0 $spacing * 0.5;

  @include mq(l) {
    margin: $spacing * 3.5 0 $spacing * 0.5;
  }
}

.call-us__headline {
  display: block;
  margin-bottom: $spacing * 0.5;

  @include mq(l) {
    margin-bottom: $spacing;
  }
}

.call-us__hotline {
  display: flex;
  align-items: center;
  margin-top: $spacing;
  margin-bottom: $spacing;
  line-height: 1;

  @include mq(l) {
    margin-bottom: $spacing * 2;
  }
}

.call-us__hotline__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: $spacing * 0.75;
  background-color: $c-pink-medium;
  border-radius: 50%;
  transition: background-color 0.3s $ease;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: $white;
  }

  .call-us__hotline:hover & {
    background-color: rgba($c-pink-medium, 0.8);
  }

  @include mq(l) {
    width: 5rem;
    height: 5rem;

    svg {
      width: 2.3rem;
      height: 2.3rem;
    }
  }
}

.call-us__hotline__number {
  @extend %fw-bold;
  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 3.6rem;
  text-decoration: none;
  transition: color $ease 0.3s;

  &:hover {
    color: rgba($c-pink-medium, 0.8);
  }

  // @include mq(l) {
  //   font-size: 4.8rem;
  // }
}

.call-us__hotline__text {
  display: block;
  margin-bottom: $spacing * 0.75;
  font-family: $ff-alt;
  font-size: 1.8rem;

  @include mq(l) {
    margin-bottom: $spacing * 1.5;
    font-size: 2.4rem;
    line-height: 1.25;
  }
}
